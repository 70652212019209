
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchBar from '@/components/search-bars/SearchBar.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import Multiselect from '@vueform/multiselect';
import moment from 'moment';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import { apiEndpoint } from '@/mixin/apiEndpoint';
const XLSX = require('xlsx');

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'Cumulative Progress Report',
  components: {
    axios,
    Form,
    Datatable,
    SearchBar,
    Multiselect,
    Swal,
    XLSX,
  },
  data() {
    return {
      api_url: '',
      report_type: '',
      load: false,
      loading: false,
      tranche: [] as any,
      TpartnerData: [] as any,
      TranceData: [] as any,
      optionsTPartner: [] as any,
      optionsTranche: [] as any,
      btnCheck: false,
      TpartnerInfos: [] as any,
      TrancheInfos: [] as any,
      start_date: '' as any,
      end_date: '' as any,
      moment: '' as any,
      serialCounters: {
        gov: 0,
        motor: 0,
        private: 0,
        edc: 0,
      },
      showTrainningStatistics: false,
      componentKey: 0,
      actionActive: false,
      cumulativeProgressInfo: [] as any,

      GrandTotalTargetTrainee: 0 as any,

      GrandTotalEnrollment: 0 as any,
      GrandTotalFemaleEnrollment: 0 as any,
      GrandTotalFemalePercentageEnrollment: 0 as any,

      GrandTotalDropout: 0 as any,
      GrandTotalFemaleDropout: 0 as any,
      GrandTotalFemalePercentageDropout: 0 as any,

      GrandTotalAssesment: 0 as any,
      GrandTotalFemaleAssesment: 0 as any,
      GrandTotalFemalePercentageAssesment: 0 as any,

      GrandTotalCertificate: 0 as any,
      GrandTotalFemaleCertificate: 0 as any,
      GrandTotalFemalePercentageCertificate: 0 as any,

      GrandTotalJobplace: 0 as any,
      GrandTotalFemaleJobplace: 0 as any,
      GrandTotalFemalePercentageJobplace: 0 as any,

      GrandTotalPercentageOfJobPlacement: 0 as any,
    };
  },
  async created() {
    await this.getAssociation();
    await this.getTranche();
      this.moment = moment;
  },
  methods: {
    getSortedEntityKeys(entities) {
      if (!entities) {
        return {};
      }

      // Get the keys of the entities object
      const keys = Object.keys(entities);

      // Sort the keys in ascending order
      keys.sort((a, b) => a.localeCompare(b));

      // Create a new object with sorted keys
      const sortedEntities = {};
      for (const key of keys) {
        sortedEntities[key] = entities[key];
      }

      // console.log(sortedEntities);

      // Return the sorted entities object
      return sortedEntities;
    },

    async printPdf() {
       ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();

      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);
      let data = `${this.VUE_APP_API_URL}/api/report/cumulative-progress-report_print`;
      axios.post(data, formData).then((response) => {
        //window.open(data, '_blank');
        document.write(response.data);
        window.print();
        location.reload();
        //open the new window and write your HTML to it
      });
    },

    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();

      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);

      await ApiService.post('report/cumulative-progress-report_pdf', formData)
        .then((response) => {
          ElNotification.closeAll();
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          ElNotification.closeAll();
          console.log(response);
        });
    },
    async exportTrainee() {
    const ws1 = XLSX.utils.table_to_sheet(
  document.querySelector('.cumulativeProgressInfo')
);

// Create a new workbook and add the worksheet
const wb = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(wb, ws1, 'Sheet1');

// Auto-fit column widths for all columns in the first sheet
const wsName = 'Sheet1';
const ws = wb.Sheets[wsName];
const range = XLSX.utils.decode_range(ws['!ref']);

for (let C = range.s.c; C <= range.e.c; ++C) {
  let maxCellLength = 0;
  let maxHeaderLength = 0;
  for (let R = range.s.r + 1; R <= range.e.r; ++R) {
    const cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
    if (cell && cell.v) {
      const cellLength = cell.v.toString().length;
      if (cellLength > maxCellLength) {
        maxCellLength = cellLength;
      }
    }
  }
  const headerCell = ws[XLSX.utils.encode_cell({ r: range.s.r, c: C })];
  if (headerCell && headerCell.v) {
    maxHeaderLength = headerCell.v.toString().length;
  }
  const columnWidth = Math.max(
    (maxCellLength + 2) * 1.2,
    (maxHeaderLength + 2) * 1.2
  );

  const cellAddress = XLSX.utils.encode_col(C) + '1';
  if (!ws[cellAddress]) {
    ws[cellAddress] = {};
  }
  ws[cellAddress].s = {
    // update the column header cell style
    font: { bold: true, color: { rgb: '000000' } },
    alignment: { horizontal: 'center' },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: { rgb: 'c6efce' },
    },
  };

  ws['!cols'] = ws['!cols'] || [];
  ws['!cols'][C] = { width: columnWidth };
}

// Save the file
const date = new Date();
const fileName = `Cumulative_Progress${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}.xlsx`;
XLSX.writeFile(wb, fileName);

    },
    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {});
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.TrancheInfos = response.data.data;
          this.TrancheInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async cumulativeProgress() {
      let entity_id = this.TpartnerData;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      this.showTrainningStatistics = true;
      await ApiService.get(
        'report/cumulative-progress-report?entity=' +
          entity_id +
          '&tranche=' +
          this.TranceData +
          '&start_date=' +
          this.start_date +
          '&end_date=' +
          this.end_date +
          '&report_type=' +
          this.report_type
      )
        .then((response) => {
          this.cumulativeProgressInfo = response.data.data.progressData;
    
          this.GrandTotalTargetTrainee =
            response.data.data.GrandTotalTargetTrainee;

          this.GrandTotalEnrollment = response.data.data.GrandTotalEnrollment;
          this.GrandTotalFemaleEnrollment =
            response.data.data.GrandTotalFemaleEnrollment;
          this.GrandTotalFemalePercentageEnrollment =
            response.data.data.GrandTotalFemalePercentageEnrollment;

          this.GrandTotalDropout = response.data.data.GrandTotalDropout;
          this.GrandTotalFemaleDropout =
            response.data.data.GrandTotalFemaleDropout;
          this.GrandTotalFemalePercentageDropout =
            response.data.data.GrandTotalFemalePercentageDropout;

          this.GrandTotalAssesment = response.data.data.GrandTotalAssesment;
          this.GrandTotalFemaleAssesment =
            response.data.data.GrandTotalFemaleAssesment;
          this.GrandTotalFemalePercentageAssesment =
            response.data.data.GrandTotalFemalePercentageAssesment;

          this.GrandTotalCertificate = response.data.data.GrandTotalCertificate;
          this.GrandTotalFemaleCertificate =
            response.data.data.GrandTotalFemaleCertificate;
          this.GrandTotalFemalePercentageCertificate =
            response.data.data.GrandTotalFemalePercentageCertificate;

          this.GrandTotalJobplace = response.data.data.GrandTotalJobplace;
          this.GrandTotalFemaleJobplace =
            response.data.data.GrandTotalFemaleJobplace;
          this.GrandTotalFemalePercentageJobplace =
            response.data.data.GrandTotalFemalePercentageJobplace;

          this.GrandTotalPercentageOfJobPlacement =
            response.data.data.GrandTotalPercentageOfJobPlacement;

          this.showTrainningStatistics = true;
          this.componentKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
